export const formatProductDataForDatalayer = (products) => {
	return products.map((prod) => {
		const { id: uniqueId, brandName: manufacturer, familyId: productCompositeId, modelNumber: productId, variants } = prod;
		const finishes = variants.map((variant) => {
			const id = Number(variant.url.split('uid=')[1]);
			return { uniqueId: id };
		});
		const productFinish = variants.find((fin) => fin.url.includes(uniqueId)).name;
		return {
			uniqueId,
			productFinish,
			manufacturer,
			productCompositeId,
			productId,
			finishes
		};
	});
};
