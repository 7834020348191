import React, { type FunctionComponent } from 'react';
import { QuickShipIcon } from '../svg/icons.component';
import { quickShip } from './quick-ship.css';

export type QuickShipProps = {
	message: string;
	cutoffMessage?: string | null;
	shippingCutoffExceedsThreshold?: boolean;
	cutoffMessageClasses?: string;
	dataTestId?: string;
	showZipCodeChanger?: boolean;
	messageClasses?: string;
};

export const QuickShip: FunctionComponent<QuickShipProps> = ({
	message,
	cutoffMessage,
	shippingCutoffExceedsThreshold,
	cutoffMessageClasses = '',
	dataTestId = '',
	messageClasses = 'fw6'
}) => (
	<div data-automation="quickship-message" data-testid={dataTestId}>
		<QuickShipIcon className={`f2 mr1 relative theme-primary omni-theme-accent ${quickShip} `} />
		<span className={messageClasses}>{message}</span>
		{shippingCutoffExceedsThreshold ? (
			<span className={`${cutoffMessageClasses} pl1`}>
				if ordered <strong>Today</strong>
			</span>
		) : cutoffMessage ? (
			<span className={`${cutoffMessageClasses} pl1`}>
				if ordered in the next <span className={'fw6'}>{cutoffMessage}</span>
			</span>
		) : null}
	</div>
);
