import gql from 'graphql-tag';

export const FacetGroupFields = gql`
	fragment FacetGroupFields on FacetGroup {
		id
		name
		range {
			min
			max
		}
		facets {
			facetId
			groupId
			value
			count
		}
		info {
			term
			description
		}
		metadata {
			hasMoreFacets
		}
		unitPrefix
		unitSuffix
	}
`;

export const SelectedFacetGroupFields = gql`
	fragment SelectedFacetGroupFields on SelectedFacetGroup {
		id
		name
		range {
			min
			max
		}
		facets {
			facetId
			value
			autoApplied
			sourceFacetId
		}
		unitPrefix
		unitSuffix
	}
`;
